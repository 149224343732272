import { FiCopy, FiExternalLink } from "react-icons/fi";
import { useLocation, useSearchParams } from "react-router-dom";
import Button from "../../../components/Button";
import ScrollToTop from "../../../components/ScrollToTop";
import { PAYMENT_METHODS } from "../../../models/PaymentMethods";
import logo from "../../../logo.png";

export default function CadastroSucesso() {
  const { state } = useLocation();
  console.log(state);
  // const { invoiceUrl } = state;

  let [searchParams] = useSearchParams();

  const paymentMethod: string | null = searchParams.get("paymentMethod");

  // console.log(paymentMethod)

  // function handlePaymentMethod() {
  //     switch (paymentMethod) {
  //         case PAYMENT_METHODS.PIX:
  //             return Pix();
  //         case PAYMENT_METHODS.CREDIT_CARD:
  //             return CreditCard();
  //         case PAYMENT_METHODS.BOLETO:
  //             return Boleto();
  //     }
  // }

  return (
    <>
      <div className="w-full flex flex-col items-center px-6 md:px-0 font-raleway">
        <ScrollToTop />
        <img
          alt="da2x logo"
          src={logo}
          className="w-52 mb-14 mt-24 md:mt-16 2xl:mt-48"
        />

        <div className="bg-secondary w-full md:w-96 flex flex-col px-6 md:px-10 py-10 rounded mb-16">
          <h1 className="text-silver font-bold text-xl md:text-2xl mb-5">
            Agora falta pouco!
          </h1>

          {paymentMethod === PAYMENT_METHODS.CREDIT_CARD ? (
            <div>
              <p className="text-silver font-raleway ">
                Você acabou de realizar o registro em nossa plataforma com
                sucesso!
              </p>
              <p className="text-silver font-raleway mt-5">
                É necessário que você confirme a sua conta clicando no link que
                enviamos para o seu email.{" "}
              </p>
              <p className="text-silver font-raleway mt-5">
                Realize o pagamento clicando no botão abaixo:
              </p>
              {/* <p className="text-silver font-raleway font-semibold mt-5">1º - Confirme a sua conta clicando no link que enviamos para o seu email </p> */}

              <a
                href="https://pay.hotmart.com/J93284655E?bid=1735860543794"
                target={"_blank"}
              >
                <Button className="mt-5 w-full flex items-center justify-center gap-2">
                  {/* <FiCopy className="" /> */}
                  Realizar pagamento
                </Button>
              </a>

              <p className="text-silver font-raleway mt-5">
                O acesso à plataforma será realizado em até 24 horas após
                confirmação do pagamento.
              </p>
            </div>
          ) : (
            // <div>
            //   <p className="text-silver font-raleway ">
            //     Você acabou de realizar o registro em nossa plataforma com
            //     sucesso!
            //   </p>
            //   <p className="text-silver font-raleway mt-5">
            //     É necessário que você confirme a sua conta clicando no link que
            //     enviamos para o seu email.{" "}
            //   </p>
            //   <p className="text-silver font-raleway mt-5">
            //     Um email será enviado assim que o seu pagamento for aprovado.
            //   </p>
            //   {/* <p className="text-silver font-raleway font-semibold mt-5">1º - Confirme a sua conta clicando no link que enviamos para o seu email </p> */}

            //   <a href="/login" target={"_blank"}>
            //     <Button className="mt-10 w-full flex items-center justify-center gap-2">
            //       {/* <FiCopy className="" /> */}
            //       Ir para página de login
            //     </Button>
            //   </a>
            // </div>
            <div>
              <p className="text-silver font-raleway ">
                Você acabou de realizar o registro em nossa plataforma com
                sucesso!
              </p>
              <p className="text-silver font-raleway mt-5">
                É necessário que você siga alguns passos para ter o seu acesso
                liberado:{" "}
              </p>
              <p className="text-silver font-raleway font-semibold mt-5">
                1º - Confirme a sua conta clicando no link que enviamos para o
                seu email{" "}
              </p>
              <p className="text-silver font-raleway font-semibold mt-5">
                2º - Realize o pagamento da sua assinatura através do botão
                abaixo:{" "}
              </p>
              <a href={state?.invoiceUrl} target={"_blank"}>
                <Button className="mt-10 w-full flex items-center justify-center gap-2">
                  {/* <FiCopy className="" /> */}
                  Realizar pagamento
                </Button>
              </a>
              <a href="/login" target={"_blank"}>
                <Button className="mt-5 w-full flex items-center justify-center gap-2 bg-transparent text-blue">
                  {/* <FiCopy className="" /> */}
                  Ir para página de login
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Pix() {
  return (
    <div className="w-full flex flex-col items-center px-6 md:px-0 font-raleway">
      <ScrollToTop />
      <h1 className="text-white font-raleway font-bold text-2xl mb-10 mt-24 md:mt-16 2xl:mt-48">
        DA2x
      </h1>

      <div className="bg-secondary w-full md:w-96 flex flex-col px-6 md:px-10 py-10 rounded mb-16">
        <h1 className="text-silver font-bold text-xl md:text-2xl mb-5">
          Agora falta pouco!
        </h1>

        <div>
          <p className="text-silver font-raleway ">
            Você acabou de realizar o registro em nossa plataforma com sucesso!
          </p>
          <p className="text-silver font-raleway mt-5">
            É necessário que você siga alguns passos para ter o seu acesso
            liberado:{" "}
          </p>
          <p className="text-silver font-raleway font-semibold mt-5">
            1º - Confirme a sua conta clicando no link que enviamos para o seu
            email{" "}
          </p>
          <p className="text-silver font-raleway font-semibold mt-5">
            2º - Realize o pagamento da sua assinatura através do link enviado
            também para o seu email{" "}
          </p>
          <a href="/login" target={"_blank"}>
            <Button className="mt-10 w-full flex items-center justify-center gap-2">
              {/* <FiCopy className="" /> */}
              Ir para página de login
            </Button>
          </a>
          {/* <p className="text-silver font-raleway font-semibold">Realize o pagamento do pix abaixo, e após aprovação, enviaremos um email para você informando que seu acesso à plataforma foi liberado.</p>
                    <img
                        alt=""
                        className="mt-10 m-auto"
                        src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz=w240-h480-rw"
                    />
                    <div className="w-full flex flex-col items-center mt-5">
                        <span
                            className="text-silver text-sm"
                            style={{
                                wordBreak: 'break-all'
                            }}
                        >
                            SDASDASDASD87AS9D7A9S87DAS87DASDA8SD798AS7D9AS54D65AS4D65AS4D56AS4D6AS4D65AS4D6ASD4ASDASDOPFDIGDPFIGDPGODPFPOKGDFGD8F9G7SD78F8DSAF645SD6445645D6F45SD645FSDKJFJHSDFJSDFSD8987978TUY789UI78UIO7OIULKJLHHKGFDSJANANAMAMBSDGHJADUHIDA8
                        </span>
                        <Button className="mt-5 w-full flex items-center justify-center gap-2">
                            <FiCopy className="" />
                            Copiar código
                        </Button>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

function CreditCard() {
  return (
    <div className="w-full flex flex-col items-center px-6 md:px-0 font-raleway">
      <ScrollToTop />
      <h1 className="text-white font-raleway font-bold text-2xl mb-10 mt-24 md:mt-16 2xl:mt-48">
        DA2x
      </h1>

      <div className="bg-secondary w-full md:w-96 flex flex-col px-6 md:px-10 py-10 rounded mb-16">
        <h1 className="text-silver font-bold text-xl md:text-2xl mb-5">
          Assinatura realizada com sucesso!
        </h1>

        <div>
          <p className="text-silver font-raleway font-semibold">
            Após aprovação do seu pagamento enviaremos um email para você
            informando que seu acesso à plataforma foi liberado.
          </p>
          {/* <img
                    alt=""
                    className="mt-10 m-auto"
                    src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz=w240-h480-rw"
                />
                <div className="w-full flex flex-col items-center mt-5">
                    <span
                        className="text-silver text-sm"
                        style={{
                            wordBreak: 'break-all'
                        }}    
                    >
                        SDASDASDASD87AS9D7A9S87DAS87DASDA8SD798AS7D9AS54D65AS4D65AS4D56AS4D6AS4D65AS4D6ASD4ASDASDOPFDIGDPFIGDPGODPFPOKGDFGD8F9G7SD78F8DSAF645SD6445645D6F45SD645FSDKJFJHSDFJSDFSD8987978TUY789UI78UIO7OIULKJLHHKGFDSJANANAMAMBSDGHJADUHIDA8
                    </span>
                <Button className="mt-5 w-full flex items-center justify-center gap-2">
                    <FiCopy className=""/>
                    Copiar código
                </Button>
                </div> */}
        </div>
      </div>
    </div>
  );
}

function Boleto() {
  return (
    <div className="w-full flex flex-col items-center px-6 md:px-0 font-raleway">
      <ScrollToTop />
      <h1 className="text-white font-raleway font-bold text-2xl mb-10 mt-24 md:mt-16 2xl:mt-48">
        DA2x
      </h1>

      <div className="bg-secondary w-full md:w-96 flex flex-col px-6 md:px-10 py-10 rounded mb-16">
        <h1 className="text-silver font-bold text-xl md:text-2xl mb-5">
          Assinatura realizada com sucesso!
        </h1>

        <div>
          <p className="text-silver font-raleway font-semibold">
            Realize o pagamento do boleto abaixo, e após aprovação, enviaremos
            um email para você informando que seu acesso à plataforma foi
            liberado.
          </p>
          {/* <img
                        alt=""
                        className="mt-10 m-auto"
                        src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz=w240-h480-rw"
                    /> */}
          <div className="w-full flex flex-col items-center mt-5">
            <span
              className="text-silver"
              style={{
                wordBreak: "break-all",
              }}
            >
              12312465456465132123.6546574897984846546546465 0
              0000000.0000.045846546.04.06
            </span>
            <Button className="mt-5 w-full flex items-center justify-center gap-2">
              <FiCopy className="" />
              Copiar código de barras
            </Button>
            <Button className="mt-5 w-full flex items-center justify-center gap-2">
              <FiExternalLink className="" />
              Visualizar boleto
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
